.App {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.App-logo {
  width: 200px;
  height: auto;
  max-width: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #696969;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Main {
  background-color: #D9D9D9;
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;             /* ใช้ความกว้างแบบ relative */
  max-width: 450px;       /* จำกัดความกว้างสูงสุด */
  margin: 20px auto;      /* จัดกลางและเว้น margin รอบๆ */
  height: auto;
}
.input-field {
  width: 100%;
  padding-inline-start: 10px;
  margin-bottom: 0px;
  border: 1px solid #CCC;
  border-radius: 10px;
  margin-left: -10px;
  height: 40px;
  font-size: 20px;
  margin-top: -50px;
}

.login-button {
  width: 50%;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #343434;
  color: white;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 15px;
}

.forgotPass{
  font-size: 24px;
  text-align: right;
  color: #343434;
  margin-top: 0px;
  margin-bottom: 0px;
}

.register {
  display: inline-block;
  font-size: 18px; /* Adjust font size as needed */
  color: #000; /* Default color for the text */
  text-align: right;
}

.register-link {
  text-align: right;
  color: blue;
  text-decoration: underline;
  cursor: pointer; /* Changes cursor to pointer when hovering over the link */
}

.register-link:hover {
  color: darkblue; /* Optional: change color when hovering over the link */
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.visibility-toggle {
  position: absolute;
  right: 15px; /* Adjust the distance from the right edge */
  top: 50%; /* Position the icon vertically in the center */
  transform: translateY(-50%); /* Ensure the icon is centered vertically */
  cursor: pointer;
  color: black;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-width: 768px) {
  .Main {
    padding: 20px;        /* ลด padding สำหรับหน้าจอเล็ก */
  }
  .App-logo{
    width: 150px;
  }
  .input-field {
    font-size: 16px;
    height: 35px;
  }
  .login-button {
    font-size: 1em;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .App-logo{
    width: 120px;
  }
}