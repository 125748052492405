/* ตัวอย่าง loader-backdrop สำหรับปิดเต็มหน้าจอ */
.loader-backdrop {
    position: fixed;
    top: 0; left: 0;
    width: 100vw; height: 100vh;
    background-color: rgba(0, 0, 0, 0.3); /* สีดำโปร่ง */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* spinner style */
  .spinner {
    width: 80px;
    height: 80px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db; /* อาจเปลี่ยนเป็นสีอื่นได้ */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* animation หมุน */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


/* SweetAlert2 */
.my-swal-size .swal2-title {
  font-size: 22px; /* หรือ 18px ตามต้องการ */
}

.my-swal-size .swal2-html-container {
  font-size: 18px; /* ข้อความหลัก */
}

.my-swal-size .swal2-confirm {
  font-size: 18px; /* ถ้าอยากให้ปุ่ม confirm ตัวใหญ่ขึ้นด้วย */
}

/* ถ้าอยากให้ icon ใหญ่ขึ้น ก็แก้ css อื่น ๆ ต่อได้ */

  