@font-face {
  font-family: 'THSarabunNew';
  src: url('./fonts/THSarabunNew.ttf') format('truetype');
}

body {
  /* font-family: 'Arial', sans-serif;
  background-color: #f0f2f5; */
  font-family: 'THSarabunNew', sans-serif;
}

/* .dashboard {
    display: flex;
    height: 100vh;
    background: #D3D3D3;
  }*/
  
  .main-content {
    flex: 1; /* takes up the remaining width */
    width: 100%;
    background: #FFFFFF; /* light background for content */
    overflow-y: auto; /* allows scrolling */
    font-family:  'THSarabunNew';
    font-size: 28px;
    transition: margin-left 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    
  }

  .main-content h1 {
    font-size: 56px;
    font-weight: bold;
    font-family: 'THSarabunNew', sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .dashboard {
    display: flex;
    height: 100vh;
    background: #ffffff;
  }
  
  .dashboard-contain {
    flex: 1;
    padding: 10px;
    height: auto;
    background: #ffffff;
    overflow-y: auto;
    font-family: 'THSarabunNew', sans-serif;
    display: flex;
    flex-direction: column;
  }
  
  .chart-row {
    display: flex;
    justify-content: center; 
    align-items: center; /* Align items vertically */
    flex-wrap: wrap; /* Allow wrapping */
    width: 100%;
    align-self: center;
}
  
  .chart-container {
    background: #e3dfdf;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 45%;
    min-width: 250px; /* Set a minimum width for smaller screens */
    height: 350px;
    /* margin: 20px; Ensure margin between containers */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'THSarabunNew', sans-serif;
    font-size: 28px; /* Increase font size */
  }
  
  .pie-chart-container, 
.chart-container {
    width: calc(50% - 40px); /* 50% width minus margins for equal spacing */
    min-width: 300px; /* Adjust minimum width if needed */
    height: 350px;
    margin: 10px;
    background: #edecec;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}

  .chart-container.bar-chart-fullwidth {
    width: 95%; /* Adjust this based on how much space you want them to take */
    min-width: 300px;
    height: 400px; /* Adjusted height for consistency */
    margin: 10px;
    background: #edecec;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }
  
 
  
  /* Add space at the bottom of the pie chart */
  .pie-chart-container canvas {
    /* margin-bottom: 20px;  */
  }

  .data-title {
    font-size: 32px;
    /* font-weight: bold; */
    text-align: center;
    margin-bottom: 2px;
  }
  
  .data-visualization-summary {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 13px; /* Decreased font size for compactness */
    line-height: 1.5;
  }
  
  .summary-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
    color: #333;
  }
  
  .summary-section {
    font-weight: bold;
    margin-top: 8px;
    color: #bdbcbc;
  }
  
  .summary-text {
    color: #555;
    margin-left: 12px;
    font-size: 12px;
    line-height: 1.4;
  }

  .slideDash-container {
    position: relative;
    width: 100%;
    height: 350px;
    background-color: #eae8e8; /* Match chart-container background */
    padding: 20px 40px; /* Increase padding on left and right to accommodate arrows */
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Match chart-container shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    overflow: hidden;
    transform-style: preserve-3d;
  }

  .slideDash-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #444;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .slideDash-content {
    /* ขนาดตัวอักษรและการจัดวางที่มีอยู่ */
    white-space: pre-line;
    font-size: 20px;
    text-align: left;
    /* เพิ่ม 4 คอลัมน์ */
    column-count: 3;
    column-gap: 10px; /* ระยะห่างระหว่างคอลัมน์ */
  }

  .slideDash-scroll {
    /* กำหนดความสูงคงที่ */
    height: 350px;
    overflow-y: auto;
    /* ซ่อน scrollbar สำหรับ Firefox */
    scrollbar-width: none;
  }
  
  /* ซ่อน scrollbar สำหรับ WebKit browsers */
  .slideDash-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* สไตล์ grid สำหรับเนื้อหาใน slide */
  .slideDash-content-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 คอลัมน์ */
    gap: 10px; /* ระยะห่างระหว่างคอลัมน์ */
    text-align: center;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    overflow-y: auto;
  }

  .slideDash-content-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 คอลัมน์ */
    gap: 10px; /* ระยะห่างระหว่างคอลัมน์ */
    text-align: center;
    width: 100%;
    max-height: 250px;  /* กำหนดความสูงคงที่ */
    overflow-y: auto;   /* ถ้ามีข้อมูลเกินจะเกิด scrollbar */
    padding: 5px;
  }
  
  .slideDash-item {
    position: relative; /* เพื่อให้ ::before อ้างอิงได้อย่างถูกต้อง */
    z-index: 0;         /* วางชั้นของตัว item ไว้เหนือ ::before */
    font-size: 20px;
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 5px;
    overflow: hidden;    /* ถ้าต้องการตัดส่วนที่เกินขอบ */
  }
  
  .slideDash-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--perc); /* รับค่าจาก style="--perc: ..." */
    height: 100%;
    background: linear-gradient(to right, rgba(76, 175, 80, 0.9), rgba(76, 175, 80, 0));
    z-index: -1;
    border-radius: inherit;
    pointer-events: none; /* ไม่ให้บังการคลิกของ item (ถ้าต้องการ) */
  }
  
  .slide-nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    font-weight: bold;
    transition: color 0.3s, transform 0.3s;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 0;
    width: 30px; /* Ensure buttons have a fixed width */
  }
  
  .slide-nav-btn:hover {
    color: #555;
    transform: translateY(-50%) scale(1.1);
  }
  
  .slide-nav-btn:nth-of-type(1) {
    left: 10px; /* Position button slightly inside */
  }
  
  .slide-nav-btn:nth-of-type(2) {
    right: 10px; /* Position button slightly inside */
  }
  
  .slideDash-content {
    white-space: pre-line; /* Preserve line breaks */
    font-size: 20px;
    text-align: center;
  }  
  
  @media (max-width: 768px) {
    .chart-row {
      flex-direction: column; /* Stack charts vertically on smaller screens */
      align-items: center; /* Center align charts */
      
    }
  
    .chart-container, .pie-chart-container {
      width: 95%; /* Make containers take full width on small screens */
      margin-bottom: 20px;
    }

    .chart-container ,.bar-chart-fullwidth {
      
      height: 400px;
  }

    /* .data-visualization-summary {
      font-size: 12px;
      padding: 8px;
      width: 95%;
    } */

    .slideDash-scroll::-webkit-scrollbar {
      display: none;
    }
  
  }

  @media (max-width: 1200px) {
    
    
   

  .slideDash-content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 คอลัมน์ */
  }

  .slideDash-scroll::-webkit-scrollbar {
    display: none;
  }

   
  }