@font-face {
  font-family: 'THSarabunNew';
  src: url('./fonts/THSarabunNew.ttf') format('truetype');
}

body {
  font-family: 'THSarabunNew', sans-serif;
  margin: 0;
  padding: 0;
}

.layout {
  display: flex;
  height: 100vh;
  overflow-y: auto;
}

/* ------- BASE SIDEBAR ------- */
.sidebar {
  background-color: #7F669D;
  color: #fff;
  overflow: hidden; /* ซ่อนสเกลเวลาเล็ก/ใหญ่ */
  transition: width 0.5s, display 0.3s;
  height: 100%;
  padding: 15px;
  position: relative;
}

/* =============== 3 โหมดหลัก (และ closed) =============== */

/* (1) โหมด open (full) */
.sidebar.open {
  width: 300px; /* sidebar กว้างเต็ม */
}

/* (2) โหมด collapsed (แสดงเฉพาะไอคอน + เส้น underline แต่ไม่โชว์ text) */
.sidebar.collapsed {
  width: 100px; /* หรือปรับตามต้องการ */
  text-align: center;
}

/* (3) โหมด hidden (ซ่อน Sidebar ทั้งหมด) */
.sidebar.hidden {
  display: none; /* ไม่แสดง sidebar เลย */
}

/* (ตัวเลือก) โหมด closed ถ้ายังมีการใช้งาน (เทียบได้กับ hidden) */
.sidebar.closed {
  display: none;
}

/* --------- เนื้อหาใน sidebar --------- */
.sidebar-content {
  background-color: #7F669D;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
  padding-left: 20px;
  position: relative;
}

.sidebar-content::-webkit-scrollbar {
  display: none; /* ซ่อน scrollbar */
}

/* หัวโลโก้ & ชื่อระบบ */
.head-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.logo-container {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  
}

.logo-container img {
  border-radius: 50%; /* ให้ภาพโลโก้เป็นวงกลม */
  background-color: #fff;
  object-fit: cover;
}

/* เมื่ออยู่โหมด collapsed => โลโก้เล็กลง */
.sidebar.collapsed .logo-container img {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  transition: width 0.3s, height 0.3s;
}

/* เมื่ออยู่โหมด open => แสดงชื่อระบบ; collapsed => ซ่อน */
.sidebar.open .head-sidebar h4 {
  display: block;
  font-size: 28px;
  margin: 0;
  /* white-space: nowrap; */
}
.sidebar.collapsed .head-sidebar h4,
.sidebar.hidden .head-sidebar h4,
.sidebar.closed .head-sidebar h4 {
  display: none;
}

/* ปุ่มแก้ไขโลโก้ ถ้ามี */
.change-logo-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #007bff;
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  width: 24px;
  height: 24px;
  transition: background-color 0.3s ease;
}
.change-logo-icon:hover {
  background-color: #0056b3;
}

/* --------- เมนูภายใน nav --------- */
.sidebar-content nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-content nav ul li {
  margin: 5px 0;
}

/* ตัวลิงก์เมนู */
.sidebar-content nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s, color 0.3s, font-size 0.3s ease;
  display: block;
  margin: 5px;
  border-radius: 4px;
  padding: 5px 8px;
}

/* ข้อความตามหลัง icon */
.sidebar-content nav ul li a span {
  margin-left: 8px;
}

/* hover เมนู */
.sidebar-content nav ul li a:hover {
  font-weight: bold;
  /* color: #69f9f9; */
  color: #474747;
  font-size: 22px;
}

/* active link */
.sidebar-content nav ul li.active a {
  font-weight: bold;
  background-color: rgba(204, 204, 204, 0.716);
  /* color: #69f9f9; */
  color: #474747;
  font-size: 22px;
}

.menu-icon {
  font-size: 24px; /* ใช้ font-size ในบางไลบรารีที่ใช้งาน Font Awesome แต่ React Icons ส่วนใหญ่ใช้ prop size */
  transition: transform 0.3s ease;
}
.sidebar-content nav ul li a:hover .menu-icon,
.sidebar-content nav ul li.active a .menu-icon {
  transform: scale(1.3);
}

/* (โหมด collapsed) ซ่อนข้อความ, ขยายไอคอน */
/* .sidebar.collapsed nav ul li a span, */
.sidebar.hidden nav ul li a span,
.sidebar.closed nav ul li a span {
  display: none;
}
.sidebar.collapsed nav ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.sidebar.collapsed nav ul li a span {
  display: none !important;
}

/* ---- LogOut Button ---- */
.logout-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}
.logout-button button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 8px;
  border: none;
  border-radius: 5px;
  background-color: #601616;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.logout-button button:hover {
  background-color: #60161699;
}

/* (โหมด collapsed/hidden) => ซ่อนข้อความ logout */
.sidebar.collapsed .logout-button button span,
.sidebar.hidden .logout-button button span,
.sidebar.closed .logout-button button span {
  display: none;
}

/* ถ้าอยากแยกตาม sidebarMode === 'open' ค่อยเพิ่ม .sidebar.open .logout-button button span { display:inline } */

/* ------ ปุ่ม Toggle ------ */
.toggle-button {
  position: absolute;
  top: 10px;
  left: 5px;
  background-color: #34343470;
  border-radius: 100px;
  /* color: #69f9f9; */
  color: #B026FF;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  z-index: 999; /* ให้อยู่เหนือ sidebar */
}

/* ------ Content (ส่วนเนื้อหา) ------ */
.content {
  margin-top: 20px;
  flex: 1;
  overflow: auto;
  /* ถ้าอยากให้เลื่อนใน content ได้ */
}

/* ------ Notification dot ------ */
.notification-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}

/* ------ จัดหมวดหมู่เมนู (Category) ------ */
.sidebar-category {
  margin-bottom: 20px;
}

.category-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  margin-bottom: 5px;
  font-family: 'THSarabunNew', sans-serif;
}

.category-underline {
  margin: 5px 0 15px 0;
  height: 1px;
  background-color: #ccc;
}

/* โหมด collapsed => ซ่อนชื่อหมวดหมู่ แต่ยังแสดงเส้นคั่น */
.sidebar.collapsed .category-title,
.sidebar.hidden .category-title,
.sidebar.closed .category-title {
  display: none;
}

/* หากต้องการให้เส้นคั่นสั้นลงใน collapsed ก็ปรับได้ */
.sidebar.collapsed .category-underline,
.sidebar.hidden .category-underline,
.sidebar.closed .category-underline {
  margin: 5px auto 15px auto;
  width: 50%; /* หรือปรับตามต้องการ */
}

.sidebar.collapsed .sidebar-category {
  margin-left: -20px;
}

.sidebar.collapsed .logout-button{
  margin-left: -10px;
}

/* ============= Responsive ============= */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
    /* position: relative;
    overflow: hidden; */
  }
  /* โหมด hidden สำหรับมือถือ */
  .sidebar.hidden {
    display: none;
  }

  /* หากอยากให้ collapsed ใน tablet, hidden ใน mobile 
     สามารถใช้ breakpoint นี้เสริมปรับ .sidebar.collapsed, etc. ได้ตามต้องการ */

  /* เนื้อหา content เต็มหน้าจอ (ไม่ถูกดัน) */
  .content {
    flex: 1; 
  }

  .toggle-button {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 10000; /* สูงกว่าทุกอย่าง */
    pointer-events: auto;
  }

  /* ซ่อน scrollbar ของ layout เอง */
  .layout {
    overflow: hidden;
  }

  /* sidebar ปรับ position absolute เพื่อ overlay ทับ content */
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: transform 0.4s ease;
    z-index: 1000;
    width: 80%; /* หรือ 100% ตามที่ต้องการ */
    transform: translateX(-100%);
    pointer-events: none; /* ป้องกัน Sidebar รับคลิกเมื่อปิด */
  }

  /* ถ้าโหมด closed => แปลว่าเราจะ slide ออก 
     (จริง ๆ จบได้เลยตรง transform: translateX(-100%)) */

  .sidebar.open {
    transform: translateX(0);
    pointer-events: auto;
  }
  /* collapsed ถ้าเคยมี (ในมือถือ) ก็อาจ treat เหมือน closed หรือซ่อนไปเลย */

  .sidebar.collapsed,
  .sidebar.closed {
    transform: translateX(-100%);
    pointer-events: none; /* ไม่รับคลิก */
  }

  /* ซ่อน/แสดง h4 & text menu 
     (ในมือถือ โหมด open ให้เมนูเต็ม, โหมด closed/collapsed => ไม่มีเมนู) */
  .sidebar.closed .sidebar-content,
  .sidebar.collapsed .sidebar-content {
    /* ตัวอย่าง: คุณอาจใช้ display:none แทน หรือจะ just transform
       แต่ถ้าไม่อยากโชว์อะไรเลย => ตรงนี้ 
    */
  }

  /* ถ้าต้องการ overlay มืดข้างหลังด้วย 
     อาจเพิ่ม .overlay-bg แบบ position:fixed; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5) 
     และสลับ display:block/none หรือ z-index:0/9999 
     แล้วผูก event ปิด sidebar เมื่อคลิก */
}
