.block_img{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-bottom: 10px;
    margin-top: 50px;
    align-items: center;
    align-self: center;
}

.addProfile_input{
    border: 1px solid #CCC;
    border-radius: 20px;
    height: 30px;
    font-size: 20px;
    padding-inline-start: 10px;
    margin-right: 15px;
}

.addAccount-button{
    width: 120px;
    height: 50px;
    border-radius: 5px;
    align-self: flex-end;
    /* margin-right: 30px; */
    margin-top: 20px;
    font-size: px;
    color: aliceblue;
    background-color: #343434;

}

@media (max-width: 1024px) {
    .main-content {
      padding: 10px;
    }
  
    .main-contain {
      padding: 10px;
      width: 100%;
    }
  
    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
  
    .block_img img {
      width: 100%;
      max-width: 150px;
      height: auto;
      align-self: center;
      justify-content: center;
    }

    .block_img label p {
      text-align: center;
      align-self: center;
      justify-content: center;
    }


  
    .Add-button,
    .Edit-button,
    .Delete-button {
      
      padding: 10px;
      margin: 10px 0;
    }
  
    button {
      max-width: 100%;
    }
  
    .main-contain div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .main-contain div div {
      flex-direction: column;
      width: 100%;
      
    }
  }