@font-face {
    font-family: 'THSarabunNew';
    src: url('./fonts/THSarabunNew.ttf') format('truetype');
  }
  
  body {
    font-family: 'THSarabunNew', sans-serif;
  }

.table-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    
  }
  
  .table-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 20px;
    align-self: flex-start;
    /* background-color: aqua; */
  }
  
  .table-title {
    font-size: 26px;
    text-align: center;
    font-family: 'THSarabunNew', sans-serif;
  }

  @media (min-width: 1180px) {
    .table-container {
        flex-direction: column;
        justify-content: space-between;
    }

    .table-section {
        width: 48%;
    }
}

@media (min-width: 768px) and (max-width: 1179px) {
  .table-container {
      flex-direction: column;
      /* justify-content: space-between; */
  }

  .table-section {
      width: 90%; /* Ensure tables don't overlap and take 48% width */
      align-self: center;
  }
}
  
  /* Media queries for responsiveness */
  @media (min-width: 1180px) {
    .table-container {
      flex-direction: row;
      justify-content: space-around;
      align-self: center;
    }
  
    .table-section {
      width: 45%;
      /* align-self: center; */
    }
  }
  
  /* Additional styles for small screens */
  @media (max-width: 767px) {
    .table-container {
        flex-direction: column; /* Stack the tables vertically on small screens */
        align-self: center;
    }

    .table-section {
        width: 100%; /* Take full width on small screens */
        align-self: center;
    }

    .table-title {
        font-size: 24px;
    }
}