/* Reset box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.user-grid {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.user-card {
  background-color: #f4f4f4;
  padding: 2%;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box; /* Ensure padding and borders are included in the element's total width and height */
}

.user-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-card h3 {
  margin: 0;
  font-size: 1.2em;
}

.user-card p {
  margin: 0;
  color: #777;
}

/* For screens 576px and above */
@media (min-width: 576px) {
  .user-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .user-card{
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}

/* For screens 768px and above */
@media (min-width: 768px) {
  .user-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .user-card{
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}

/* For screens 1024px and above */
@media (min-width: 1024px) {
  .user-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .user-card{
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}

/* For screens 1200px and above */
@media (min-width: 1200px) {
  .user-grid {
    grid-template-columns: repeat(5, 1fr);
  }
  .user-card{
    align-items: center;
    justify-content: center;
    align-self: center;
  }
}
