/* AboutUs.css */

/* คอนเทนเนอร์ใหญ่ */
.content-container {
    width: 95%;
    /* กำหนดความสูงตายตัวเป็น 85vh ตามที่ต้องการ */
    height: 90vh;
    margin: 20px auto 0 auto;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* สำคัญ: ใช้ align-items: stretch เพื่อยืดความสูงของ Panel ให้เท่ากัน */
    align-items: stretch;
  }
  
  /* ฝั่งซ้าย */
  .left-panel {
    display: flex;
    flex-direction: column;
    width: 49.5%;
    height: 100%;
    border-radius: 15px;
    overflow-y: auto;
    background-color: #FAFAFA;
    box-shadow: 0 0 10px 5px rgb(65, 65, 65, 0.544);
    border-bottom: 5px solid black;
  
    /* ซ่อน scrollbar สำหรับเบราว์เซอร์ต่าง ๆ */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;      /* Firefox */
  }
  .left-panel::-webkit-scrollbar {
    display: none;             /* Chrome, Safari, Opera */
  }
  
  /* ฝั่งขวา */
  .right-panel {
    display: flex;
    flex-direction: column;
    width: 49.5%;
    height: 100%;
    border-radius: 15px;
    justify-content: space-between;
    
  }

  .showPlan-panel {
    width: 100%;
    height: 39%;
    align-self: center;
    border-radius: 15px;
    background-color: #d3d3d3;
    overflow-y: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    box-shadow: 0 0 10px 5px rgb(65, 65, 65, 0.544) ; 
    border-bottom: 5px solid black; 
  }

  .showPlan-panel::-webkit-scrollbar {
    display: none;             /* Chrome, Safari, Opera */
  }

  .changePlan-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #4d058b;
    background-color: #fff;
    transition: transform 0.2s ease, background-color 0.2s ease, border 0.2s ease;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Hover state: ขยายเล็กน้อยและเปลี่ยนพื้นหลัง */
  .changePlan-button:hover {
    transform: scale(1.05);
    background-color: #f0faff;
  }
  
  /* Active state (ตอนที่กดอยู่) */
  .changePlan-button:active {
    transform: scale(0.95);
  }
  
  /* สถานะที่ถูกเลือก (active) */
  .changePlan-button.active {
    border: 3px solid #cc00ff;  /* ตัวอย่าง: border สีเขียวที่เด่นขึ้น */
    background-color: #f3a2f7;
  }
  
  /* Disabled state: ลด opacity และไม่สามารถคลิกได้ */
  .changePlan-button.disabled {
    background-color: #e0e0e0;
    opacity: 0.6;
    cursor: not-allowed;
  }

  .help-panel {
    width: 100%;
    height: 59%;
    align-self: center;
    border-radius: 15px;
    background-color: #d3d3d3;
    overflow-y: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    box-shadow: 0 0 10px 5px rgb(65, 65, 65, 0.544) ; 
    border-bottom: 5px solid black; 
  }

  .help-panel::-webkit-scrollbar {
    display: none;             /* Chrome, Safari, Opera */
  }
  
  /* ทำให้ Responsive */
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
      height: auto;  /* ให้สูงอัตโนมัติเมื่อสลับเป็น column */
    }
    .left-panel,
    .right-panel {
      width: 100%;
      height: auto;
      margin-bottom: 20px; /* เว้นระยะระหว่าง Panel */
    }

    .showPlan-panel{
      width: 100%;
      height: 200px;
    }

    .help-panel {
        width: 100%;
        height: 400px;
        margin-top: 20px; /* เว้นระยะระหว่าง Panel */
        overflow-y: auto;
      }
  }
  