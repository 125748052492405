.main{
    display: flex;
    /* height: 100%; */
    justify-content: center;
    flex-direction: column;
}
.main-contain{
    display: flex;
    align-self: center;
    background-color: rgba(247, 247, 247, 0.956);
    width: 100%;
    min-height: 200px;
    max-width: 95%;
    border-width: 20px;
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    font-size: 24px;
    box-shadow: 0px 10px 15px rgba(65, 65, 65, 0.544);
    padding-bottom: 20px;
    margin-bottom: 40px;
    overflow-x: auto;
}

.header-page{
  align-self: center;
  justify-self: center;
  /* border-width: 50px; */
  border-radius: 10px 10px 0 0;
  margin-bottom: 0px;
  background: linear-gradient(135deg, #7F669D 0%, #7F669D 100%); 
  text-align: center;
  width: 1800px;
  max-width: 95%;
  padding-top: 15px;
  margin-top: 20px;
  color: #FFF;
  box-shadow: 0px 4px 10px rgba(65, 65, 65, 0.544);
  /* border-left: 5px solid black ; */
}

.search-field{
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}
.search-button{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.Add-button{
    width: 150px;
    height: 50px;
    border-radius: 5px;
    align-self: self-end;
    
    margin-bottom: 15px;
    font-size: 22px;
    color: aliceblue;
    background-color: #5e0087;
}
.Edit-button{
    background-color: rgb(116, 115, 114);
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    width: 120px;
    height: 40px;
    border-width: 0;
    color: #FFF;
}   
.Delete-button{
    background-color: rgb(100, 14, 31);
    border-radius: 5px;
    margin-left: 5px;
    width: 120px;
    height: 40px;
    color: #FFF;
    border-width: 0;
}

.verify-wel{
    background-color: rgb(61, 61, 61);
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    width: 35%;
    height: 20%;
    color: #FFF;
    border-width: 0;
}

.Next-button{
margin: 5px;
border-radius: 5px;
width: 10%;
background-color: #5e0087;
color: #FFF;
}

.Role-button, .UpSkill-button, .Notice-button {
  background-color: transparent;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.Role-button:hover, .UpSkill-button:hover, .Notice-button:hover {
  color: #000; /* Change this to your preferred hover color */
}

.Role-button {
  color: #005c8b; /* blue */
}

.UpSkill-button {
  color: #007498; /* green */
}

.Notice-button {
  color: #008ba0; /* yellow */
}

.fillter-button{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.Previous-button{
  margin: 5px;
  border-radius: 5px;
  width: 10%;
  background-color: #cf60ff;
  color: #FFF;
}

.table-container {
  display: flex;
  flex-direction: column; /* Stack tables vertically by default */
  align-items: flex-start;
  width: 100%;
  gap: 20px; /* Add space between tables */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.table-section {
  width: 100%; /* Ensure the table takes full width */
  margin-bottom: 20px;
}

.table {
  border-radius: 10px;
  overflow: hidden;
  width: 100%; /* Make the table fit within its container */
  table-layout: auto; /* Adjust table layout for responsiveness */
  word-wrap: break-word; /* Prevent content from overflowing cells */
}

.table thead th {
  border-top: none;
  background-color:#7F669D;
  color: #fff;
}

.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table tbody tr:last-child {
  border-bottom: none;
}

.table tbody tr:hover {
  background-color: #e0e0e0 !important; /* Change to your desired hover color */
}

.center-text {
  text-align: center;
}

@media (min-width: 768px) {
  .table-container {
      flex-direction: row; /* Display tables side by side on larger screens */
      justify-content: space-between;
      /* margin-right: 5%; */
  }

  .table-section {
      width: 48%; /* Each table takes half the width */
  }
}

@media (max-width: 767px) {
  .table-container {
      flex-direction: column; /* Stack tables vertically on small screens */
  }

  .table-section {
      width: 100%; /* Tables take full width on smaller devices */
  }
}

.header-page {
  align-self: center;
  justify-self: center;
  border-width: 50px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0px;
  /* background: linear-gradient(135deg, #BEBEBE 0%, #f5f5f5 100%);  */
  text-align: center;
  width: 100%;
  max-width: 95%;
  padding: 15px;
  margin-top: 20px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
}

.search-field {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (min-width: 1024px){
  .search-field {
    align-items: center;
    justify-content: center;
    width: 100%;
  } 
  
}

@media (max-width: 1025px) {
    .main-content {
      /* padding: 10px; */
      padding-left: 40px;
      overflow-x: scroll;
    }
  
    .main-contain {
      padding: 10px;
      width: 100% ; /* Ensure it uses full width on very small screens */
      height: auto;
      /* overflow-y: scroll; */
    }
  
    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
  
    .block_img img {
      width: 100%;
      max-width: 150px;
      height: auto;
    }
  
    .Add-button{
      padding: 0px;
      margin: 0px 0;
         
    }

    .fillter-button{
      align-self: center;
      margin-right: 5px;
    }

    .Edit-button,
    .Delete-button,
    .verify-wel,
    .Next-button,
    .Previous-button {
      display: flex;
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      align-items: center;
      justify-content: center;
    }
  
    button {
      max-width: 100%;
    }
  
    .main-contain div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .main-contain div div {
      flex-direction: column;
      width: 100%;
      height: auto;
      /* overflow-y: scroll; */
    }

    .form-field {
      width: 100% !important;
      margin-bottom: 10px !important;
    }
  
    .form-row {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 5px !important;
      align-self: center;
    }

    .table tbody tr:hover {
      background-color: #e0e0e0; /* Change to your desired hover color */
    }

  }