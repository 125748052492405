/* Fonts and Body Setup */
@font-face {
  font-family: 'THSarabunNew';
  src: url('./fonts/THSarabunNew.ttf') format('truetype');
}

body {
  font-family: 'THSarabunNew', sans-serif;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Service Container */
.service-container {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(
    to bottom,
    #95e6ff 0%,
    #ebfaff 50%,
    #e1F5FE 100%
  ); */
  /* background: linear-gradient(
    to bottom,
    #b925f9 0%,
    #db88ff 75%,
    rgb(238, 197, 255) 100%
  ); */
  background-color: #7F669D;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 5px 20px;
  height: 70px; /* Adjust height */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky; /* Makes it stick to the top */
  top: 0; /* Position at the top of the page */
  z-index: 1000; /* Ensure it stays above other elements */
  /* overflow: hidden; */
}

/* Navbar Left Section */
.navbar-left {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between logo and Plan button */
  flex-wrap: wrap;
}

/* Logo Container */
.navbarlogo-container {
  display: flex;
  align-items: center;
}

.navbarlogo {
  height: 50px;
  width: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 25px; /* Optional: Slight rounding for aesthetics */
}

/* Button Container */
.button-container {
  display: flex;
  gap: 10px;
}

.nav-button {
  background-color: #f8f8f8;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-family: 'THSarabunNew', sans-serif;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.nav-button:hover {
  background-color: #eaefef;
}

.nav-button.plan-button {
  border: 2px solid transparent; /* Invisible border for consistent sizing */
  font-size: 22px; /* Adjust font size specifically for the Plan button */
  font-weight: bold; /* Make the Plan button text bold */
}

.nav-button.plan-button:hover {
  border-color: #007BFF;
}

/* Add border and background for active/selected state */
.nav-button.active {
  border: 2px solid #007BFF; /* Active border color */
  background-color: rgba(0, 123, 255, 0.2); /* Slightly darker background for active state */
  color: #007BFF; /* Text color for active state */
}

/* Slideshow Container */
.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh; /* Fix the height to 60% of the viewport */
  min-height: 400px; /* Ensure a minimum height for smaller screens */
  width: 100%;
  background-color: rgba(184, 185, 186, 0.5); /* Added opacity */
  overflow: hidden;
  position: relative;
  box-shadow: none; /* Ensure no shadow */
  border: none; /* Remove any borders */
}

/* Slide Items */
.slide-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}

/* Custom Slide Content */
.custom-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.first-slide {
  background: url('./icon/DALL·E 2024-12-04 16.22.44 - A highly realistic and professional HR-themed wallpaper, featuring people in a corporate office environment collaborating in a meeting room. The focus.webp') no-repeat center center;
  background-size: cover; /* Ensures the image covers the full container */
  background-attachment: scroll; /* Ensures the image scrolls naturally */
  position: absolute; /* Absolute to fit within the parent container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.first-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent overlay */
  z-index: 1; /* Overlay stays above background but below content */
}

.first-slide > * {
  position: relative;
  z-index: 2; /* Content stays above the overlay */
}

/* Arrows */
.arrow {
  cursor: pointer;
  font-size: 96px; /* Increased arrow size */
  color: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  transition: transform 0.3s, color 0.3s;
  z-index: 3;
}

.arrow:hover {
  transform: translateY(-50%) scale(1.2);
  color: #007BFF;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.content-center-wrapper {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-center {
  display: flex;
  flex-direction: column;
  /* align-self:self-start; */
  align-items: center;
  /* justify-content: center; */
  position: relative;
  /* background-color: aqua; */
  width: 50%;
  height: 100%;
  z-index: 2; /* Ensure content is above overlay */
  text-align: center;
  /* margin-left: 40px;*/
} 

.left-content {
  margin-left: 40px; /* Adds spacing for left content */
}

.right-content {
  display: flex;
  justify-content: center;
  align-items: center; /* Centers the message box vertically */
  margin-right: 40px; /* Adds spacing for right content */
  height: 100%; /* Ensure it fills the height of its parent */
}

/* Circle Logo */
.circle-logo {
  width: 150px; /* Adjust size as needed */
  height: 150px;
  border-radius: 50%; /* Makes the logo circular */
  /* background-color: #ffffff; */
  /* padding: 20px;  */
  margin-top: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
}

/* Quote Text */
.quote {
  margin-top: 10px; /* Space between logo and text */
  font-size: 56px; /* Adjust size as needed */
  color: #333; /* Text color */
  font-weight: bold;
  text-align: center;
}

.desc-quote{
  text-align: 'center';
  color: '#333';
  font-size: 32px;
  font-weight: bold;
  line-height: '1.0';
  margin-top: -20px;
}

.message-box {
  background-color: rgba(255, 255, 255, 0.8); /* White background with opacity */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the box */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
  font-size: 18px;
  color: #333; /* Text color */
  line-height: 1.5;
  width: 80%; /* Adjust width as needed */
  max-width: 600px; /* Optional: Set a maximum width */
  text-align: left; /* Align text to the left */
  margin: 0 auto; /* Center the box horizontally */
}

/* Phrase Section */
.phrase-section {
  text-align: center;
  padding: 40px 20px;
  margin: 40px auto;
  width: 100%; /* Full width for the plan section */
}

.phrase-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.phrase-title {
  font-size: 36px;
  font-weight: bold;
  color: #f1f1f1;
  margin: 0 10px;
}

.phrase-line {
  width: 50%;         /* ปรับเป็น 50% หรือ px ตามต้องการ */
  max-width: 200px;   /* ถ้าต้องการจำกัดความกว้างสูงสุดอีกชั้น */
  height: 2px;
  background-color: #c1c1c1;
}

/* Plan Section */
.plan-section {
  text-align: center;
  padding: 40px 20px;
  margin: -40px auto;
  width: 100%; /* Full width for the plan section */
}

/* Plan Header */
.plan-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

/* Plan Title */
.plan-title {
  font-size: 36px;
  font-weight: bold;
  color: #f1f1f1;
  margin: 0 20px; /* Space between lines and text */
}

/* Straight Line */
.line {
  flex: 1;
  height: 2px;
  background-color: #f1f1f1; /* Line color */
}

/* Wrapper ที่ครอบ .plan-cards เพื่อทำ animation height + gradient */
.plan-cards-wrapper {
  position: relative;
  transition: max-height 0.6s ease; /* ความยาว animation */
  overflow: hidden;                /* ซ่อนส่วนเกิน */
}

/* ตอนย่อ (collapsed) กำหนดความสูงจำกัดเช่น 500px (ปรับตาม Layout) */
.plan-cards-wrapper.collapsed {
  max-height: 200px;
}

/* ใช้ pseudo-element สร้าง Gradient ที่ด้านล่าง */
.plan-cards-wrapper.collapsed::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px; /* สูงเท่าไรให้ fade ได้ */
  pointer-events: none; /* ไม่ให้บังการคลิก */
  background: linear-gradient(
    rgba(235, 250, 255, 0),
    rgba(127, 102, 157, 1)
  );
  /* หรือปรับสีด้านล่างให้เป็นสี background .service-container */
}

/* ตอนขยาย (expanded) ให้ max-height ใหญ่พอจะโชว์หมด */
.plan-cards-wrapper.expanded {
  max-height: 3000px; /* หรือ auto ไม่สามารถ transition ได้ง่าย => ใช้ค่าประมาณ */
}

.plan-cards-wrapper.expanded::after {
  /* เอา gradient ออก */
  background: transparent;
}

/* ปุ่ม Show More */
.show-more-button {
  background-color: #eee;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  color: #333;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s;
  
  flex-direction: column;
}
.show-more-button:hover {
  background-color: #ddd;
}

/* Plan Cards Section */
.plan-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 20px; /* Space between cards */
  margin-top: 20px;
  padding: 0 20px; /* Add padding for spacing */
}

/* Card Content Alignment */
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns all content to the left */
  justify-content: flex-start;
  text-align: left; /* Ensures text is left-aligned */
  padding: 20px;
  gap: 10px; /* Adds space between items */
  border-radius: 10px;
}

.card h3 {
  text-align: center; /* Center the title */
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; /* Add some space below the title */
  width: 100%; /* Ensures it spans the full width for centering */
}

.card ul {
  list-style: none; /* Removes default list styling */
  padding: 0; /* No padding for list */
  margin: 0; /* No margin for list */
}

.card li {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  margin-bottom: 10px; /* Space between list items */
  font-size: 24px; /* Adjust font size for readability */
  color: #555; /* Text color */
}

.card li .icon {
  margin-right: 10px; /* Space between the icon and text */
  color: #28a745; /* Green color for the check icon */
}

.icon {
  color: #28a745; /* Green color for the check icon */
  margin-right: 8px; /* Space between the icon and text */
}

/* Dots Container */
.dots-container {
  display: flex;
  position: absolute; /* Position the dots relative to the slideshow container */
  bottom: 20px; /* Place dots at the bottom of the slideshow */
  left: 50%; /* Center the dots horizontally */
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px; /* Space between dots */
  z-index: 3; /* Ensure dots are above the overlay */
}

/* Dots */
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc; /* Inactive dot color */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 3; /* Ensure dots are always visible */
}

/* Active Dot */
.dot.active {
  background-color: #007BFF; /* Active dot color */
  transform: scale(1.2); /* Slightly enlarge the active dot */
}

/* General Styles for Smaller Screens */
body, html, .service-container {
  font-size: 14px; /* Adjust the base font size for smaller screens */
}

/* Key Feature Section */
.feature-section {
  padding: 40px 20px;
  width: 100%;
  text-align: center;
}

/* Grid 3 คอลัมน์ */
.keyfeature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* 3 คอลัมน์ */
  gap: 20px;
  max-width: 1200px; 
  margin: 20px auto 0; /* center */
}

/* แต่ละกล่องฟีเจอร์ */
.keyfeature-item {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0,0,0,0.1);

  /* กำหนดความสูง fix */
  height: 200px; 
  overflow: hidden; 
  transition: all 0.4s ease;
}

/* ไอคอน: ให้อยู่ทางซ้าย หรือจะ center ก็ได้ */
.icon-wrapper {
  font-size: 36px;
  margin-bottom: 10px;
  color: #007BFF; 
  /* ถ้าต้องการให้อยู่ซ้าย:
     text-align: left;
     margin-right: auto;
  */
}

/* หัวข้อ */
.feature-title {
  margin: 0 0 5px 0;
  text-align: left !important; /* ถ้าต้องการชิดซ้าย */
  font-size: 20px;
  color: #333;
}

/* คำอธิบาย */
.feature-desc {
  position: relative;
  text-align: left;
  line-height: 1.4;
  margin: 0;

  /* ทำ padding-bottom ไว้ไม่ให้ข้อความติดขอบล่าง */
  padding-bottom: 40px; 
}

/* Gradient บังตอนปกติ (collapsed) ด้วย pseudo-element */
.keyfeature-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  transition: opacity 0.4s ease;
}

/* ซ่อนคำอธิบายส่วนล่าง */
.keyfeature-item.collapsed {
  height: 100px; /* ถ้าต้องการโชว์ข้อความเพียงบางส่วน */
}

/* ตอนปกติ: แสดงบางส่วน => ให้ desc ถูกบังด้วย height container + gradient
   ถ้าอยาก force ข้อความ truncated ให้ max-height เล็กลง 
   (เช่น 60px) + overflow hidden 
*/

/* Hover => ขยาย เพื่อเลื่อน desc ขึ้นมา */
.keyfeature-item:hover {
  height: auto;             /* auto เพื่อให้ desc โผล่เต็ม */
  min-height: 200px;        /* กันรูป layout shift */
  overflow: visible;        /* เปิด overflow */
}

.keyfeature-item:hover::after {
  opacity: 0; /* ลบ gradient */
}

/* Highlight Section */
.highlight-section {
  padding: 40px 20px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.highlight-section .plan-title {
  /* ถ้าต้องการแก้ไขขนาดหรือสไตล์เฉพาะ Title ตรงนี้ */
}

/* Grid สำหรับรูป 2 คอลัมน์ */
.highlight-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 รูปต่อแถว */
  gap: 20px;
  margin-top: 20px;
  max-width: 1200px; /* กำหนดความกว้างสูงสุด (ถ้าต้องการ) */
  margin-left: auto;
  margin-right: auto; 
}

/* กล่องที่ห่อรูปแต่ละรูป */
.highlight-image {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #4f4f4f;
  overflow: hidden; /* ตัดส่วนเกินกรณีรูปใหญ่กว่า */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* รูป */
.highlight-image img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

/* Animation Hover */
.highlight-image:hover {
  transform: translateY(-5px);    /* ยกขึ้น */
  box-shadow: 0 8px 20px rgba(0,0,0,0.5); /* เงาเพิ่ม */
}
.highlight-image:hover img {
  /* ถ้าอยากให้รูปขยายอีกหน่อยก็ได้ */
  transform: scale(1.02);
}

/* video section */
.video-section {
  text-align: center;
  padding: 40px 20px;
  margin: 40px auto;
  width: 100%;
}

/* Container สำหรับ iframe เพื่อให้ responsive */
.video-container {
  display: flex;
  flex-wrap: wrap;       /* ถ้ามีหลายวิดีโอ */
  justify-content: center;
  gap: 20px;             /* ช่องว่างระหว่างวิดีโอ */
  max-width: 1200px;
  margin: 0 auto;
}

.video-container iframe {
  /* ทำ responsive โดยใช้ max-width 100% */
  max-width: 100%;
  /* height: 480px; */
  border: none;
  border-radius: 10px; /* ปรับค่าตามต้องการ */
  overflow: hidden;    /* เพื่อให้ขอบเนื้อหา iframe ถูกตัดตามความโค้ง */
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* ถ้าต้องการเงาเล็กน้อย */
}

/* Footer */
.footer-container {
  display: flex;
  justify-content: space-between; /* ด้านซ้ายเป็น Administrator © ... และด้านขวาเป็น Terms of Service, Privacy */
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px 20px;
  margin-top: 40px; /* เว้นระยะขอบบนจากแผนการใช้งาน (Plans) */
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}

/* ด้านซ้ายของ Footer */
.footer-left {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 5px;
}

/* ด้านขวาของ Footer */
.footer-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* ปุ่มใน Footer */
.footer-link {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #000; /* สีตัวอักษรปุ่ม */
  text-decoration: underline;
}

.footer-link:hover {
  color: #8db9e8; /* เปลี่ยนสีเล็กน้อยเมื่อ Hover */
}

@media (max-width: 768px) {
  .keyfeature-grid {
    grid-template-columns: 1fr;
  }

  .keyfeature-item {
    height: auto; 
    /* ถ้าต้องการโชว์เต็มบนมือถือ ไม่ต้อง hover hidden */
  }

  .highlight-grid {
    grid-template-columns: 1fr; /* 1 รูปต่อแถว */
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 ratio */
    height: 0;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

  .footer-container {
    flex-direction: column;       /* จัดเรียง footer เป็นคอลัมน์ */
    align-items: center;      /* จัดตัวอักษรชิดซ้าย */
    gap: 10px;                    /* ระยะห่างระหว่าง block */
    padding: 20px;               /* ปรับ padding ให้เหมาะสม */
  }

  /* จัดการให้ footer-left อยู่ก่อน footer-right ในแนวตั้งได้ */
  .footer-left {
    margin-bottom: 10px;         /* เพิ่มระยะห่างระหว่าง footer-left และ footer-right */
  }

  .footer-right {
    align-self: center;        /* หรือจะปรับเป็น flex-start / center ก็ได้ตามต้องการ */
  }
}

/* Adjust Navbar for Smaller Screens */
@media (max-width: 768px) {
  .navbar {
    /* flex-wrap: nowrap; Prevent wrapping */
    /* justify-content: space-between;
    align-items: center; */
    flex-direction: column; 
    align-items: flex-start; 
    height: auto;
  }

  .navbar-left {
    /* flex: 1;  */
    flex-direction: column;
    gap: 10px;
    width: 100%; 
  }

  .logo {
    height: 30px; /* Reduce logo size */
    width: auto;
    margin-top: 5px;
  }

  .button-container {
    /* flex: 1;
    justify-content: flex-end; 
    gap: 5px; */
    width: 100%; 
    display: flex;
    justify-content: space-between; 
    gap: 10px;
  }

  .nav-button {
    /* font-size: 14px; 
    padding: 6px 10px;  */
    font-size: 14px; 
    padding: 8px 12px; 
    width: 100%; 
    text-align: center; 
  }
}

/* Adjust Slideshow for Smaller Screens */
@media (max-width: 768px) {
  .slideshow-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Smooth snap to each slide */
    display: flex;
    flex-wrap: nowrap; /* Ensure slides stay in one row */
  }

  .slide-item {
    flex: 0 0 100%; /* Each slide takes 100% of the container width */
    scroll-snap-align: center; /* Snap alignment */
  }

  .arrow {
    font-size: 48px; /* Smaller size for mobile */
    top: 45%; /* Adjust position */
    transform: translateY(-50%);
    z-index: 5;
  }

  .dots-container {
    gap: 5px; /* Reduce space between dots */
  }

  .dot {
    width: 8px;
    height: 8px;
  }

  .dot.active {
    transform: scale(1.1); /* Slightly reduce the scale for active dot */
  }
}

/* Responsive Plan Cards Section */
@media (max-width: 1024px) {
  .plan-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on medium screens */
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .plan-cards {
    grid-template-columns: 1fr; /* 1 card per row on small screens */
  }

  .card {
    font-size: 18px; /* Adjust font size for card text */
    padding: 15px;
  }

  .card h3 {
    font-size: 20px;
    margin-bottom: 10px; /* Reduce spacing */
  }

  .card li {
    font-size: 16px; /* Smaller font size for card details */
    margin-bottom: 5px; /* Reduce spacing between items */
  }
}

/* Adjust Slide Content */
@media (max-width: 768px) {
  .content-center-wrapper {
    flex-direction: column; /* Stack content vertically */
    text-align: center;
  }

  .left-content, .right-content {
    width: 100%;
    margin: 0;
  }

  .circle-logo {
    width: 100px;
    height: 100px;
  }

  .quote {
    font-size: 20px; /* Reduce quote size */
  }

  .desc-quote{
    font-size: 18px;
  }

  .message-box {
    width: 90%; /* Make the message box fit the screen */
  }
}

/* Responsive Dots and Arrows */
@media (max-width: 768px) {
  .arrow {
    display: block;
  }

  .dots-container {
    margin-top: 10px; /* Adjust spacing for dots */
  }
}

@media (max-width: 768px) {
  .message-box {
    width: 90%; /* Allow message box to fit the screen */
    margin: 0 auto; /* Center it */
    padding: 15px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
    padding-bottom: 20px;
  }
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  width: 100%;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f8f8f8;
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 80px; /* Below the navbar */
  left: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

@media (max-width: 768px) {
  .navbar-left,
  .button-container {
    display: none; /* Hide the navbar content on small screens */
  }

  .hamburger {
    display: block; /* Show hamburger menu on small screens */
  }
}