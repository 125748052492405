/* General Layout */
.calendar-dashboard {
  display: flex;
}

.calendar-main-content {
 
  display: flex;
  flex-direction: column;
 

  color: #fff;
  flex: 1; /* takes up the remaining width */
    /* width: 100%; */
    background: #E7E7E7; /* light background for content */
    overflow-y: auto; /* allows scrolling */
    font-family:  'THSarabunNew';
    font-size: 26px;
    transition: margin-left 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
  
}

.calendar-main {
  width: 100% !important;
  max-width: 1200px;
  padding: 20px;
  background-color: #444;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  overflow-x: auto !important;
}

/* Header */
.calendar-header-page header {
  text-align: center;
  margin-bottom: 20px;
}

.calendar-header-page h1 {
  font-size: 2rem;
  color: #fff;
}

/* Calendar */
.react-calendar {
  width: 100%;
  background-color: #555;
  border: none;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-size: 16px !important;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.react-calendar__navigation button {
  border-radius: 5px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin: 0 5px;
}

.react-calendar__navigation button:hover {
  background-color: #0056b3;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important; /* 7 equal columns for each day */
  grid-gap: 10px !important; /* Space between tiles */
  justify-items: center;
  padding: 0 10px !important;
  font-size: 16px !important;
}

/* Day Header */
.react-calendar__month-view__weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  margin-bottom: 10px;
  font-size: 16px !important;
}


/* Tile for each date */
.react-calendar__tile {
  width: 100% !important; /* Make sure tiles take full available space */
  max-width: none !important;
  position: relative;
  border-radius: 5px;
  background-color: #666;
  color: #fff;
  margin: 5px !important;
  padding: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.react-calendar__tile--now {
  border: 2px solid #228B22 !important; /* Green border for today's date */
  background-color: #777;
  border-radius: 5px;
}

.react-calendar__tile--active {
  background-color: #007bff;
  color: white;
  border: 2px solid #0056b3; /* Add border to active date */
}

.react-calendar__tile:hover {
  background-color: #888;
  cursor: pointer;
}

/* Notes Section */
.calendar-notes-section {
  margin-top: 20px;
  text-align: center;
}

.calendar-notes-section h2 {
  margin-bottom: 10px;
}

.calendar-notes-section ul {
  list-style: none;
  padding: 0;
}

.calendar-notes-section li {
  background-color: #666;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.calendar-notes-section textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #888;
  background-color: #555;
  color: #fff;
  padding: 10px;
}

.calendar-notes-section button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #888;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-notes-section button:hover {
  background-color: #999;
}

.note-item {
  display: flex;
  justify-content: space-between;
  background-color: #666;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.note-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.delete-button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #888;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #999;
}

/* Add Dot for Dates with Notes */
.dot-container {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row; /* Align dots in a row */
  justify-content: center;
  align-items: center;
  gap: 3px; /* Space between each dot */
}

/* Each dot */
.dot {
  height: 8px;
  width: 8px;
  background-color: red; /* Dot color */
  border-radius: 50%;
}

.language-toggle {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

/* .modal-content {
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 20px;
} */

/* .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-body {
  padding: 10px 20px;
} */

.note-input {
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
}

/* Maintain consistent style when focused (typing) */
.note-input:focus {
  background-color: #444 !important; /* Keep the background the same */
  color: #fff !important;            /* Keep the text color white */
  outline: none;                     /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional: add subtle box-shadow for focus */
}

.save-button {
  background-color: #28a745;
  border: none;
  padding: 10px;
  font-size: 1.2rem;
}

.save-button:hover {
  background-color: #218838;
}

/* .modal-footer {
  border-top: none;
  padding-top: 0;
  justify-content: center;
} */

.calendar-modal .modal-content {
  background-color: #333 !important;
  color: #fff !important;
  border-radius: 10px;
  border: none;
  padding: 20px;
  border: 2px solid #E2F7F7;
}

.calendar-modal .modal-header {
  border-bottom: 2px solid white !important;
}

.calendar-modal .modal-footer {
  background-color: #333 !important;
  border-top: none !important;
}

.calendar-modal .form-control {
  background-color: #444 !important;
  color: #fff !important;
  border: 1px solid #666 !important;
}