@font-face {
  font-family: 'THSarabunNew';
  src: url('./fonts/THSarabunNew.ttf') format('truetype');
}

body {
  font-family: 'THSarabunNew', sans-serif;
}

.contact-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: #BEBEBE; */
  color: white;
}

.contact-container {
  background-color: rgba(47, 47, 47, 0.8);
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  align-self: center;
  justify-content: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.form-group {
  margin-bottom: 15px;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin-left: 30px;
}

.form-group label {
  font-size: 18px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #000;
}

.form-group input[type="file"] {
  padding: 5px;
}

.submit-button {
  background-color: #282828;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1e1e1e;
}

.quote {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  font-style: italic;
}

@media (max-width: 768px) {
  .contact-container {
    width: 90%;
  }
}
